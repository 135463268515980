import { routeToUrl } from "@app-routes";
import { AppLogo } from "@shared/components/AppLogo";
import { Favicon } from "@shared/components/helmet/favicon";
import { LoginPage } from "@uxf/cms/pages/login-page";
import Router from "next/router";

export default LoginPage({
    pageTitle: "Admin | Předplátko.cz",
    title: "Přihlaste se",
    subTitle: "Registrace je možná pouze s pozvánkou.",
    onLoginDone: async (_, redirectUrl) => {
        // router.push se nepoužívá, protože je potřeba provolat serverové getInitialProps po přihlášení
        window.location.href = redirectUrl ?? routeToUrl("cms/index");
    },
    onForgottenPassword: () => Router.push(routeToUrl("cms/forgotten-password")),
    ui: {
        Logo: (
            <>
                <Favicon />
                <AppLogo variant="symbol" />
            </>
        ),
    },
});
