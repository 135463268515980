import logoIcon from "@public/static/images/logo-icon.svg";
import logo from "@public/static/images/logo.svg";
import { RasterImage } from "@uxf/ui/raster-image";
import { FC } from "react";

interface Props {
    variant?: "full" | "symbol";
}

export const AppLogo: FC<Props> = (props) => {
    const variant = props.variant ?? "full";

    if (variant === "full") {
        return <RasterImage src={logo.src} width={152} height={53} alt="Přeplátko" className="w-36" />;
    }

    return <RasterImage src={logoIcon.src} width={67} height={83} alt="Přeplátko" className="w-16" />;
};
